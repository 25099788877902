'use client';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import styled from 'styled-components';

import BaseButton from '@/src/components/BaseComponents/BaseButton';
import BaseIcon from '@/src/components/BaseComponents/BaseIcon';

export const Wrapper = styled(FormControl)`
  &.MuiFormControl-root {
    margin-bottom: 0.9rem;
  }
`;

export const Label = styled(InputLabel)`
  background-color: ${({theme}) => theme.colors.grey000};
`;

export const ShowHideButton = styled(BaseButton)`
  display: flex;
  justify-content: flex-end;
  min-width: 38px;

  &:hover {
    background-color: transparent;
  }
`;

export const Icon = styled(BaseIcon)`
  fill: rgb(204, 204, 204);
  width: 20px;
  height: 20px;
`;
