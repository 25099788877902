import {FC} from 'react';

import * as Styled from './styled';

interface Props {
  speed?: number;
  height?: number | string;
  width?: number | string;
}

const BaseLoader: FC<Props> = ({speed = 0.75, height = 50, width = 50, ...other}) => (
  <Styled.Wrapper $speed={speed} $height={height} $width={width} {...other} data-qa-auto="spinner" />
);

export default BaseLoader;
