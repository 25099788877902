import {FC} from 'react';

import * as Styled from './styled';

interface Props {
  text: string;
  handleSubmit: () => void;
  disabled: boolean;
  displayLoader: boolean;
}

const SubmitButton: FC<Props> = ({text, handleSubmit, disabled, displayLoader}) =>
  displayLoader ? (
    <Styled.Loader />
  ) : (
    <Styled.Button onClick={handleSubmit} disabled={disabled} data-qa-auto="submit-button">
      {text}
    </Styled.Button>
  );

export default SubmitButton;
