import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';

import {font_large} from '@/theme/fonts';

import BaseLink from '../../../../components/BaseComponents/BaseLink';

export const PageContainer = styled(Grid)`
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f9;
  min-height: calc(100vh - 134px);
  display: block !important;
`;

export const PageContent = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

export const Header = styled.h3`
  width: 100%;
  text-align: center;
`;

export const HasAccount = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  & > button,
  > a {
    width: 30%;
    background-color: ${({theme}) => theme.colors.orange090};
    box-shadow: 0 2px 4px #00000014;
    color: ${({theme}) => theme.colors.grey000};
    &:hover {
      background-color: ${({theme}) => theme.colors.orange100};
      box-shadow: none;
      color: ${({theme}) => theme.colors.grey000};
    }
  }
`;

export const Form = styled.div`
  max-width: 500px;
  width: 70%;
  margin: 20px auto;
  background-color: transparent;
  transition: all 0.2s ease;
`;

export const FormWrapper = styled(Paper)`
  &.MuiPaper-root {
    min-height: 369px;
  }
`;

export const Account = styled.div`
  ${font_large};
  display: flex;
  color: ${({theme}) => theme.colors.grey100};
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  @media ${({theme}) => theme.breakpoints.maxSm} {
    flex-direction: column;
    text-align: center;
  }
`;

export const ForgotPass = styled.div`
  ${font_large};
  color: ${({theme}) => theme.colors.blue100};
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${({theme}) => theme.breakpoints.maxSm} {
    text-align: center;
  }
`;

export const RegisterLink = styled(BaseLink)`
  &.MuiTypography-root {
    color: ${({theme}) => theme.colors.blue100};
    border: 1px solid ${({theme}) => theme.colors.blue100};
    border-radius: 20px;
    padding: 8px 16px;
    margin-left: 16px;
    width: 90px;

    &:hover {
      text-decoration: none;
      background: ${({theme}) => theme.colors.grey030};
    }
  }
`;
