'use client';

import styled from 'styled-components';

export const Wrapper = styled.div<{$speed: number; $height: number | string; $width: number | string}>`
  background: transparent;
  width: ${({$width}) => (typeof $width === 'number' ? `${$width}px` : $width)};
  height: ${({$height}) => (typeof $height === 'number' ? `${$height}px` : $height)};
  border-radius: 50%;
  border: 2px solid rgb(255, 120, 2);
  border-bottom-color: transparent !important;
  display: inline-block;
  animation: spin ${({$speed}) => $speed}s infinite linear;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
