'use client';

import TextField from '@mui/material/TextField';
import styled from 'styled-components';

export const CaptchaBlock = styled.div`
  margin-bottom: 15px;
`;

export const Input = styled(TextField)`
  &.MuiFormControl-root {
    margin-bottom: 0.9rem;

    input {
      div > input {
        padding: 14.4px;
      }
      & p {
        margin-left: 0;
      }
    }
  }
`;

export const FormItem = styled.form`
  margin-top: 10px;
`;
