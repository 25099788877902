'use client';

import styled from 'styled-components';

import BaseButton from '@/src/components/BaseComponents/BaseButton';
import BaseLoader from '@/src/components/BaseComponents/BaseLoader';
import {font_large} from '@/theme/fonts';

export const Loader = styled(BaseLoader)`
  display: flex;
  margin: auto auto 10px;
`;

export const Button = styled(BaseButton)`
  &.MuiButton-root {
    ${font_large};
    color: ${({theme}) => theme.colors.grey000};
    border-radius: 30px;
    height: 48px;
    width: 100%;
    margin-bottom: 10px;
    background: ${({theme}) => theme.colors.blue100};
    text-transform: none;

    &:hover {
      background: ${({theme}) => theme.colors.blue090};
    }

    &:disabled {
      background: rgb(218, 219, 252);
    }
  }
`;
