import {FC, useEffect, useMemo, useState} from 'react';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Grid from '@mui/material/Grid2';
import {useSearchParams} from 'next/navigation';
import queryString, {ParsedQuery} from 'query-string';

import AuthSocial from '@/src/components/AuthModal/AuthSocial';
import LoginForm from '@/src/components/AuthModal/Login/components/Form';
import BaseButton from '@/src/components/BaseComponents/BaseButton';
import BaseLink from '@/src/components/BaseComponents/BaseLink';
import PageHeader from '@/src/components/PageHeader';
import useConfig from '@/src/hooks/useConfig';
import {useSession} from '@/src/hooks/useSession';
import {isEmailValid} from '@/src/lib/RegistrationUtils';

import * as Styled from './styled';
import Consts from '../../AuthorizationConsts';

const {DO_HAVE_BLEND_ACC, FORGOT, NO_ACC, HAVE_ACC, HAVE_NOT_ACC, REG} = Consts;

const AuthView: FC = () => {
  const config = useConfig();
  const session = useSession();
  const query = useSearchParams();
  const search = query?.get('search') || '';
  const [email, setCurrentEmail] = useState<string>('');
  const [hasBlendAccount, setHasBlendAccount] = useState<boolean | null>(null);

  const queryParams: ParsedQuery<string> = useMemo(
    () => queryString.parse(search ? search.replace(/[+]/g, '%2B') : ''),
    [search]
  );

  useEffect(() => {
    queryParams.mail && isEmailValid(String(queryParams.mail)) && setCurrentEmail(String(queryParams.mail));
  }, [queryParams]);

  useEffect(() => {
    setHasBlendAccount(!(session?.snData && !session?.snData?.emailExists));
  }, [session?.snData]);

  return (
    <Styled.PageContainer container>
      <PageHeader title="Sign In" icon={<PersonOutlineOutlinedIcon fontSize="large" />} />
      <Grid style={{marginTop: 10}} container spacing={2}>
        <Grid size={{xs: 12}}>
          <Styled.FormWrapper elevation={3}>
            <Styled.PageContent>
              <Styled.Form>
                {config?.socialNetwork && <AuthSocial onCancelSN={() => setHasBlendAccount(true)} />}
                {hasBlendAccount || hasBlendAccount === null ? (
                  <>
                    <LoginForm emailUpdatedHandler={setCurrentEmail} agreeTermsValue emailParam={email} />

                    <Styled.ForgotPass>
                      <BaseLink data-qa-auto="forgotpass" href={`/auth/resetPassword${email ? '?email=' + email : ''}`}>
                        {FORGOT}
                      </BaseLink>
                    </Styled.ForgotPass>
                    <Styled.Account>
                      {NO_ACC}
                      <Styled.RegisterLink data-qa-auto="register-button" href="/auth/register">
                        {REG}
                      </Styled.RegisterLink>
                    </Styled.Account>
                  </>
                ) : (
                  <>
                    <Styled.Header>{DO_HAVE_BLEND_ACC}</Styled.Header>
                    <Styled.HasAccount>
                      <BaseButton data-qa-auto="has-blend-button" onClick={() => setHasBlendAccount(true)}>
                        {HAVE_ACC}
                      </BaseButton>
                      <BaseButton data-qa-auto="no-blend-button" href="/auth/register">
                        {HAVE_NOT_ACC}
                      </BaseButton>
                    </Styled.HasAccount>
                  </>
                )}
              </Styled.Form>
            </Styled.PageContent>
          </Styled.FormWrapper>
        </Grid>
      </Grid>
    </Styled.PageContainer>
  );
};

export default AuthView;
