'use client';

import {FC, useEffect, useMemo} from 'react';

import {useSearchParams} from 'next/navigation';

import {useGetUserDataQuery} from '@/src/lib/store/stores/api';
import {isXSS} from '@/src/utils/xss';

import AuthView from '../AuthView';

const AuthorizationView: FC = () => {
  const searchParams = useSearchParams();
  const {isLoading, data: userData} = useGetUserDataQuery();

  const isLoggedIn: boolean = useMemo(() => !!userData?.id, [userData?.id]);

  const returnFromUrl = useMemo(() => searchParams?.get('return') || '', [searchParams]);

  useEffect(() => {
    if (isLoggedIn) {
      window.location.href = returnFromUrl && !isXSS(returnFromUrl) ? returnFromUrl : '/cpanel';
    }
  }, [isLoggedIn, returnFromUrl]);

  return isLoggedIn && !isLoading ? null : <AuthView />;
};

export default AuthorizationView;
